<template>
    <swiper class="main-section-swiper" v-bind="swiperOptions" :modules="modules">
        <swiper-slide class="main-section-swiper-item" v-for="(item, i) in slides" :key="i">
            <section class="main-section">
                <img class="main-section__img" :class="item.icon.class"
                     :src="require('@/assets/'+item.icon.name+'.png')" alt="">
                <div class="wrap main-section__content">
                    <h1 class="main-section__title" style="white-space: pre-line">
                        {{ item?.title[getLang] }}
                    </h1>
                    <p class="main-section__description mb-60">
                        {{ item?.description[getLang] }}
                    </p>
                    <div class="d-flex">
                        <bytton class="button"
                                :class="['button__base']"
                                @click="setForm(true)"
                        >
                            {{ item?.button.title[getLang] }}
                        </bytton>
                    </div>
                </div>
            </section>
        </swiper-slide>
        <div class="button button__slide button__slide--next main-section__slide-next"></div>
    </swiper>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay'
import 'swiper/css/navigation';
import "swiper/css/effect-fade";
import {Navigation, EffectFade, Autoplay} from 'swiper';

export default {
    name: "MainSection",
    props: {
        value: Object
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            modules: [Navigation, EffectFade, Autoplay],
            swiperOptions: {
                effect: 'fade',
                hashNavigation: {
                    watchState: true,
                },
                centeredSlides: true,
                slidesPerView: 1,
                loopedSlides: 1,
                spaceBetween: 0,
                navigation: {
                    prevEl: '.swiper-prev',
                    nextEl: '.main-section__slide-next',
                },
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                },
                allowTouchMove: false,
                // autoplay: {
                //     delay: 2500,
                //     disableOnInteraction: false,
                // },
                // breakpoints:{
                //     370:{
                //         slidesPerView: 2.1
                //     },
                //     480:{
                //         slidesPerView: 2.5
                //     },
                //     1050:{
                //         slidesPerView: 1
                //     }
                // },
                autoHeight: false,
                loop: true,
                observe: true,
                observeParents: true,
                observeSlideChildren: true,
                speed: 1000,
                modules: [Autoplay]
            }
        }
    },
    computed: {
        ...mapGetters([
            "getLang"
        ]),
        slides() {
            return this.value.slide
        },
    },
    methods: {
        ...mapMutations([
            "setForm"
        ])
    }
}
</script>

<style scoped>

</style>